import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { now } from '@internationalized/date';
import { SegmentedControl, MultipleSegmentedControl, SegmentedChoice, TextField } from '@entur/form';
import { GridContainer, GridItem } from '@entur/grid';
import { BannerAlertBox } from '@entur/alert';
import { DatePicker, TimePicker } from '@entur/datepicker';
import { Playground } from '~/components/playground/Playground';
import { Dropdown } from '@entur/dropdown';
import { ImportStatement } from '~/components/ImportStatement';
import { CodeText } from '@entur/typography';
import { BoldIcon, ItalicIcon, UnderlineIcon } from '@entur/icons';
import PageHeader from '~/components/PageHeader';
import { CodeBlock } from '~/components/CodeBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'() => {\n  const [traveltime, setTraveltime] = React.useState(null)\n  return (\n    <div style={{ marginBottom: \'1rem\' }}>\n      <SegmentedControl\n        label=\"Velg reisetidspunkt\"\n        onChange={selectedValue => setTraveltime(selectedValue)}\n        selectedValue={traveltime}\n      >\n        <SegmentedChoice value=\"na\">Nå</SegmentedChoice>\n        <SegmentedChoice value=\"avreise\">Avreise</SegmentedChoice>\n        <SegmentedChoice value=\"ankomst\">Ankomst</SegmentedChoice>\n      </SegmentedControl>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      SegmentedControl,
      MultipleSegmentedControl,
      SegmentedChoice,
      TextField,
      GridContainer,
      GridItem,
      BannerAlertBox,
      DatePicker,
      TimePicker,
      Playground,
      Dropdown,
      ImportStatement,
      CodeText,
      BoldIcon,
      ItalicIcon,
      UnderlineIcon,
      PageHeader,
      CodeBlock,
      DefaultLayout,
      _frontmatter
    }} style={{
      width: "55%",
      margin: "0 auto"
    }} mdxType="Playground">
  {() => {
        const [traveltime, setTraveltime] = React.useState(null);
        return <div style={{
          marginBottom: '1rem'
        }}>
          <SegmentedControl label="Velg reisetidspunkt" onChange={selectedValue => setTraveltime(selectedValue)} selectedValue={traveltime} mdxType="SegmentedControl">
            <SegmentedChoice value="na" mdxType="SegmentedChoice">Nå</SegmentedChoice>
            <SegmentedChoice value="avreise" mdxType="SegmentedChoice">Avreise</SegmentedChoice>
            <SegmentedChoice value="ankomst" mdxType="SegmentedChoice">Ankomst</SegmentedChoice>
          </SegmentedControl>
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "segmentedcontrol"
    }}>{`SegmentedControl`}</h3>
    <ImportStatement imports="SegmentedControl" mdxType="ImportStatement" />
    <Props of={SegmentedControl} mdxType="Props" />
    <p><inlineCode parentName="p">{`SegmentedControl`}</inlineCode>{` wrapper to eller flere `}<inlineCode parentName="p">{`SegmentedChoice`}</inlineCode>{`-komponenter, og lar brukeren velge mellom forskjellige valg. For å kunne velge flere valg, bruk `}<inlineCode parentName="p">{`MultipleSegmentedControl`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "segmentedchoice"
    }}>{`SegmentedChoice`}</h3>
    <ImportStatement imports="SegmentedChoice" mdxType="ImportStatement" />
    <Props of={SegmentedChoice} mdxType="Props" />
    <p><inlineCode parentName="p">{`SegmentedChoice`}</inlineCode>{` wrapper et valg inni en `}<inlineCode parentName="p">{`SegmentedControl`}</inlineCode>{` eller `}<inlineCode parentName="p">{`MultipleSegmentedControl`}</inlineCode>{`. Send inn en unik `}<inlineCode parentName="p">{`value`}</inlineCode>{` som identifiserer valget, og send inn hva du vil (typisk en tekst eller et ikon) som `}<inlineCode parentName="p">{`children`}</inlineCode>{`.`}</p>
    <BannerAlertBox variant="information" title="Husk beskrivelser for skjermlesere" mdxType="BannerAlertBox">
  Om du sender inn ikoner eller tekster som krever en visuell kontekst, er det
  viktig at du sender inn en <CodeText mdxType="CodeText">aria-label</CodeText> prop, eller bruker{' '}
  <CodeText mdxType="CodeText">VisuallyHidden</CodeText>
  (fra <CodeText mdxType="CodeText">@entur/a11y</CodeText>) for å gi valget en god beskrivelse.
    </BannerAlertBox>
    <h3 {...{
      "id": "multiplesegmentedcontrol"
    }}>{`MultipleSegmentedControl`}</h3>
    <ImportStatement imports="MultipleSegmentedControl" mdxType="ImportStatement" />
    <Props of={MultipleSegmentedControl} mdxType="Props" />
    <p><inlineCode parentName="p">{`MultipleSegmentedControl`}</inlineCode>{` er lik som `}<inlineCode parentName="p">{`SegmentedControl`}</inlineCode>{`, men tillater brukeren å velge flere valg på en gang. Dette gjør at `}<inlineCode parentName="p">{`selectedValue`}</inlineCode>{`-propen må være et objekt, der nøklene korresponderer til `}<inlineCode parentName="p">{`value`}</inlineCode>{`-verdien i de nøstede `}<inlineCode parentName="p">{`SegmentedChoice`}</inlineCode>{`-komponentene. `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` vil også bli kalt med samme objektet.`}</p>
    <p>{`Et eksempel kan være:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<MultipleSegmentedControl
  selectedValue={{ italics: true, bold: false }}
  onChange={{ italics, bold } => changeFormatting({ italics, bold })}
>
  <SegmentedChoice value="italics"><em>Italics</em></SegmentedChoice>
  <SegmentedChoice value="bold"><strong>Bold</strong></SegmentedChoice>
</MultipleSegmentedControl>
`}</code></pre>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Segmented Control benyttes fremst til å velge en setting. De tilbyr et kontekstuelt valg, som er knyttet 1:1 til andre
objekter eller skjemagrupper. Valgt segment definerer ‘regler’ for verdiene i tilknyttede felter.
Segmented controls benyttes altså ofte som velgere/settings for andre felter, f.eks. om verdien som angis skal
i prosent eller kroner, eller om tidspunkt skal gjelde for avreise eller ankomst. De plasseres gjerne ovenfor/før
feltet de styrer over, eller under/etter i tilfelle konteksten tillater dette.`}</p>
    <p>{`Segmented control har samme funksjon som en radiobutton, det vil si at én av verdiene er forhåndsvalgt, og den predefinerte
verdien kan byttes ut med en annen i samme rekke. Kun én av verdiene innen en segmented control kan velges.`}</p>
    <p>{`For å oppnå best mulig brukervennlighet, bør en Segmented Control ikke ha fler enn fem segmenter. Alle segmenter bør ha samme bredde.
Det er lettere for brukeren å “tappe” bredere segmenter.`}</p>
    <p>{`Bruk kun en Segmented Control når alternativene er forhåndsdefinerte. Hold segmentinnholdet kort og konsistent.
Segmented Controls skal benyttes med kun tekst eller tegn, ikke ikon + tekst.`}</p>
    <h3 {...{
      "id": "eksempler"
    }}>{`Eksempler`}</h3>
    <h4 {...{
      "id": "eksempel-1"
    }}>{`Eksempel 1`}</h4>
    <Playground __position={6} __code={'() => {\n  const [type, setType] = React.useState(null)\n  const [dateTime, setDateTime] = React.useState(now(\'Europe/Oslo\'))\n  return (\n    <GridContainer\n      spacing=\"medium\"\n      style={{ marginBottom: \'1rem\', width: \'65%\' }}\n    >\n      <GridItem small={12}>\n        <SegmentedControl\n          label=\"Velg reisetidspunkt\"\n          onChange={selectedValue => setType(selectedValue)}\n          selectedValue={type}\n        >\n          <SegmentedChoice value=\"nå\">Nå</SegmentedChoice>\n          <SegmentedChoice value=\"avreise\">Avreise</SegmentedChoice>\n          <SegmentedChoice value=\"ankomst\">Ankomst</SegmentedChoice>\n        </SegmentedControl>\n      </GridItem>\n      <GridItem small={12} large={6}>\n        <DatePicker\n          label=\"Reisedato\"\n          selectedDate={dateTime}\n          onChange={date => setDateTime(date)}\n        />\n      </GridItem>\n      <GridItem small={12} large={6}>\n        <TimePicker\n          label=\"Klokkeslett\"\n          selectedTime={dateTime}\n          onChange={time => setDateTime(time)}\n        />\n      </GridItem>\n    </GridContainer>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      SegmentedControl,
      MultipleSegmentedControl,
      SegmentedChoice,
      TextField,
      GridContainer,
      GridItem,
      BannerAlertBox,
      DatePicker,
      TimePicker,
      Playground,
      Dropdown,
      ImportStatement,
      CodeText,
      BoldIcon,
      ItalicIcon,
      UnderlineIcon,
      PageHeader,
      CodeBlock,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [type, setType] = React.useState(null);
        const [dateTime, setDateTime] = React.useState(now('Europe/Oslo'));
        return <GridContainer spacing="medium" style={{
          marginBottom: '1rem',
          width: '65%'
        }} mdxType="GridContainer">
        <GridItem small={12} mdxType="GridItem">
          <SegmentedControl label="Velg reisetidspunkt" onChange={selectedValue => setType(selectedValue)} selectedValue={type} mdxType="SegmentedControl">
            <SegmentedChoice value="nå" mdxType="SegmentedChoice">Nå</SegmentedChoice>
            <SegmentedChoice value="avreise" mdxType="SegmentedChoice">Avreise</SegmentedChoice>
            <SegmentedChoice value="ankomst" mdxType="SegmentedChoice">Ankomst</SegmentedChoice>
          </SegmentedControl>
        </GridItem>
        <GridItem small={12} large={6} mdxType="GridItem">
          <DatePicker label="Reisedato" selectedDate={dateTime} onChange={date => setDateTime(date)} mdxType="DatePicker" />
        </GridItem>
        <GridItem small={12} large={6} mdxType="GridItem">
          <TimePicker label="Klokkeslett" selectedTime={dateTime} onChange={time => setDateTime(time)} mdxType="TimePicker" />
        </GridItem>
      </GridContainer>;
      }}
    </Playground>
    <h4 {...{
      "id": "eksempel-2"
    }}>{`Eksempel 2`}</h4>
    <Playground __position={7} __code={'() => {\n  const [type, setType] = React.useState(null)\n  return (\n    <GridContainer\n      spacing=\"medium\"\n      style={{ marginBottom: \'1rem\', width: \'50%\' }}\n    >\n      <GridItem small={12}>\n        <SegmentedControl\n          label=\"Velg påslag eller avlslag\"\n          onChange={selectedValue => setType(selectedValue)}\n          selectedValue={type}\n        >\n          <SegmentedChoice value=\"percent\">%</SegmentedChoice>\n          <SegmentedChoice value=\"kroner\">Kr</SegmentedChoice>\n        </SegmentedControl>\n      </GridItem>\n      <GridItem small={12}>\n        <TextField label=\"Pris\" />\n      </GridItem>\n    </GridContainer>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      SegmentedControl,
      MultipleSegmentedControl,
      SegmentedChoice,
      TextField,
      GridContainer,
      GridItem,
      BannerAlertBox,
      DatePicker,
      TimePicker,
      Playground,
      Dropdown,
      ImportStatement,
      CodeText,
      BoldIcon,
      ItalicIcon,
      UnderlineIcon,
      PageHeader,
      CodeBlock,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [type, setType] = React.useState(null);
        return <GridContainer spacing="medium" style={{
          marginBottom: '1rem',
          width: '50%'
        }} mdxType="GridContainer">
        <GridItem small={12} mdxType="GridItem">
          <SegmentedControl label="Velg påslag eller avlslag" onChange={selectedValue => setType(selectedValue)} selectedValue={type} mdxType="SegmentedControl">
            <SegmentedChoice value="percent" mdxType="SegmentedChoice">%</SegmentedChoice>
            <SegmentedChoice value="kroner" mdxType="SegmentedChoice">Kr</SegmentedChoice>
          </SegmentedControl>
        </GridItem>
        <GridItem small={12} mdxType="GridItem">
          <TextField label="Pris" mdxType="TextField" />
        </GridItem>
      </GridContainer>;
      }}
    </Playground>
    <h4 {...{
      "id": "eksempel-3"
    }}>{`Eksempel 3`}</h4>
    <Playground __position={8} __code={'() => {\n  const [type, setType] = React.useState(null)\n  const [provider, setProvider] = React.useState(null)\n  const items = [\'Entur\', \'Vy\']\n  return (\n    <GridContainer\n      spacing=\"medium\"\n      style={{ marginBottom: \'1rem\', width: \'50%\' }}\n    >\n      <GridItem small={12}>\n        <SegmentedControl\n          label=\"Velg billettype\"\n          onChange={setType}\n          selectedValue={type}\n        >\n          <SegmentedChoice value=\"sone\">Sone</SegmentedChoice>\n          <SegmentedChoice value=\"strekning\">Strekning</SegmentedChoice>\n        </SegmentedControl>\n      </GridItem>\n      <GridItem small={12}>\n        <Dropdown\n          label=\"Reisetilbyder\"\n          placeholder=\"Velg selskap\"\n          items={items}\n          selectedItem={provider}\n          onChange={setProvider}\n        />\n      </GridItem>\n    </GridContainer>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      now,
      SegmentedControl,
      MultipleSegmentedControl,
      SegmentedChoice,
      TextField,
      GridContainer,
      GridItem,
      BannerAlertBox,
      DatePicker,
      TimePicker,
      Playground,
      Dropdown,
      ImportStatement,
      CodeText,
      BoldIcon,
      ItalicIcon,
      UnderlineIcon,
      PageHeader,
      CodeBlock,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [type, setType] = React.useState(null);
        const [provider, setProvider] = React.useState(null);
        const items = ['Entur', 'Vy'];
        return <GridContainer spacing="medium" style={{
          marginBottom: '1rem',
          width: '50%'
        }} mdxType="GridContainer">
        <GridItem small={12} mdxType="GridItem">
          <SegmentedControl label="Velg billettype" onChange={setType} selectedValue={type} mdxType="SegmentedControl">
            <SegmentedChoice value="sone" mdxType="SegmentedChoice">Sone</SegmentedChoice>
            <SegmentedChoice value="strekning" mdxType="SegmentedChoice">Strekning</SegmentedChoice>
          </SegmentedControl>
        </GridItem>
        <GridItem small={12} mdxType="GridItem">
          <Dropdown label="Reisetilbyder" placeholder="Velg selskap" items={items} selectedItem={provider} onChange={setProvider} mdxType="Dropdown" />
        </GridItem>
      </GridContainer>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      